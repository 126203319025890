<template>
  <v-row class="staff-new">
    <v-col cols="12" class="pa-0">
      <div class="title">
        <div class="text-sm-h5 text-h6 font-weight-bold">
          <router-link to="/staff" class="white--text">
            <v-icon class="white--text text-sm-h4 text-h5 mb-1">mdi-chevron-left</v-icon>New staff
          </router-link>
        </div>
      </div>
      <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5">
        <div class="text-uppercase text-h5 font-weight-bold">General</div>
        <div class="px-sm-10 py-sm-5">
          <v-row>
            <v-col cols="12" md="4">
              <div v-if="!image">
                <v-img @click="changeAvatar()" width="256" src="@/assets/img/ava.svg" class="link mx-md-0 mx-auto"></v-img>
              </div>
              <div v-else class="mx-auto">
                <v-img @click="changeAvatar()" width="256" height="216" cover :src="image" class="link"></v-img>
              </div>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="firstName"
                :error-messages="firstnameErrors"
                label="First Name"
                filled
                outlined
                dense
                required
                color="input"
                maxlength="50"
              ></v-text-field>
              <v-text-field
                v-model="lastName"
                :error-messages="lastnameErrors"
                label="Last Name"
                filled
                outlined
                dense
                required
                color="input"
                maxlength="50"
              ></v-text-field>
              <v-text-field
                v-model="email"
                :error-messages="emailErrors"
                label="Email Address"
                filled
                outlined
                dense
                required
                color="input"
              ></v-text-field>
              <v-select
                :items="roleList"
                v-model="role"
                :error-messages="roleErrors"
                label="Role"
                item-text="title"
                item-value="name"
                filled
                outlined
                dense
                color="input"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-divider class="my-10"></v-divider>
          <div class="text-sm-right text-center">
            <v-btn elevation="0" width="180" height="48" color="primary" @click="create">Confirm</v-btn>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  validations: {
    firstName: { required },
    lastName: { required },
    email: { required, email },
    role: { required },
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      role: '',
      file: '',
      image: '',
      error: null,
    };
  },
  mounted() {
    this.$store.dispatch('getStaffRoles');
  },
  computed: {
    roleList() {
      return this.$store.getters.staffRoleList;
    },
    firstnameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) {
        return errors;
      }
      !this.$v.firstName.required && errors.push('Please enter your first name');
      this.error == 'first_name__invalid' && errors.push('Provided first name is not valid');
      return errors;
    },
    lastnameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) {
        return errors;
      }
      !this.$v.lastName.required && errors.push('Please enter your last name');
      this.error == 'last_name__invalid' && errors.push('Provided last name is not valid');
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) {
        return errors;
      }
      !this.$v.email.email && errors.push('Provided email address is not valid');
      !this.$v.email.required && errors.push('Please enter your email address');
      this.error == 'email__invalid' && errors.push('Provided email address is not valid');
      this.error == 'email__exists' && errors.push('Provided email address is already registered. Please use another one');
      return errors;
    },
    roleErrors() {
      const errors = [];
      if (!this.$v.role.$dirty) {
        return errors;
      }
      !this.$v.role.required && errors.push('Please enter your role');
      this.error == 'role__invalid' && errors.push('Provided role is not valid');
      return errors;
    },
  },
  methods: {
    notifi(btn) {
      if (btn == 'updatePhoto') {
        this.$notify({ message: 'Your staff picture was successfully updated', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'create') {
        this.$notify({ message: 'Your staff has been created', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (this.error == 'confirmation__too_often') {
        this.$notify({
          message: 'Sorry, but email confirmation requests limit exceeded. Try again later',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (this.error == 'email__already_confirmed') {
        this.$notify({ message: 'Email is already confirmed', type: 'warning', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (this.error == 'photo__required') {
        this.$notify({ message: 'Please select the staff photo', type: 'error', horizontalAlign: 'center', verticalAlign: 'top' });
      }
    },
    async create() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.error = null;
        const formData = new FormData();
        formData.append('first_name', this.firstName);
        formData.append('last_name', this.lastName);
        formData.append('email', this.email);
        formData.append('role', this.role);
        formData.append('photo', this.file);
        await this.$store
          .dispatch('createStaff', formData)
          .then((res) => {
            this.notifi('create');
            this.$router.push(`/staff/${res.data.id}`);
          })
          .catch((e) => {
            this.error = e.response.data.error[0];
            this.notifi();
          });
      }
    },
    changeAvatar() {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          self.file = this.files[0];
          var reader = new FileReader();
          reader.onload = (e) => {
            self.image = e.target.result;
          };
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
  },
};
</script>
